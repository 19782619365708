export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const GET_IMAGES = 'GET_IMAGES';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const LOG_IN = 'LOG_IN';
export const SIGN_UP = 'SIGN_UP';
export const CHECK_AUTH = 'CHECK_AUTH';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const PUT_LIKE = 'PUT_LIKE';
export const PUT_DISLIKE = 'PUT_DISLIKE';
export const GET_RATE = 'GET_RATE';
export const LOG_OUT = 'LOG_OUT';
export const EDIT_STATEMENT = 'EDIT_STATEMENT';
export const GET_SUBS = 'GET_SUBS';
export const SET_STATUS = 'SET_STATUS';
export const SET_SUB = 'SET_SUB';
export const GET_UNVERIFIED = 'GET_UNVERIFIED';
export const GET_GIT = 'GET_GIT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const USER_LOADING = 'USER_LOADING';
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const GET_STATIC = 'GET_STATIC';
export const ADD_STATIC = 'ADD_STATIC';
export const EDIT_STATIC = 'EDIT_STATIC';
