import React from 'react';

function Undefined() {
	return (
		<div className="post single">
			<p className="post-title">There is no such page</p>
		</div>
	);
}

export default Undefined;
